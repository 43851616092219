<template>
  <div class="cardBills">
    <mt-header :title="$t('cardBills')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)" icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="content">
      <ul>
        <li v-for="(item, index) in list" :key="index">
          <div class="wrap">
            <div class="title border-b">
              <div class="name">{{ $t('amount') }}：</div>
              <div>{{ item.amount | formatMoney }}</div>
            </div>
            <div class="row">
              <div class="name">{{ item.paidAt }}</div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import callPhone from '@/components/callPhone.vue'
import { cardBills } from '../../../utils/api'
export default {
  name: 'CardBills',
  components: {
    callPhone
  },
  data() {
    return {
      list: [],
    }
  },
  computed: {
    ...mapState(["user"]),
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      await this.loadMore()
      this.$indicator.close();
    },
    async loadMore() {
      const formData = new FormData()
      formData.append('id', this.$route.query.id)
      await this.$axios({
        method: 'post',
        url: cardBills,
        data: formData
      })
        .then((e) => {
          if (e.status.code == '000') {
            this.list = e.body
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.cardBills {
  background-color: #FBFBFF;
  ul,li {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  ul {
    flex-grow: 1;
    position: relative;
    li {
      display: block;
      padding: 10px 15px;
      text-decoration: none;
      border-top: 1px solid #EAECEC;
      border-bottom: 1px solid #EAECEC;
      margin-bottom: 30px;
      background: #fff;
      display: flex;
      align-items: center;
    }
    .wrap {
      flex: 1;
      border-radius: 10px;
    }
    .title {
      display: flex;
      align-items: center;
      height: 45px;
      color: #111111;
      font-size: 18px;
      img {
        width: 20px;
        margin: 0 15px;
      }
      div {
        color: #000;
        padding-left: 20px;
      }
    }
    .row {
      display: flex;
      height: 30px;
      color: #A8A8A8;
      font-size: 14px;
      font-weight: bold;
      align-items: center;
    }
  }
}
</style>